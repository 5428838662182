body {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #606060;
    /*background-color: #212529;*/
}

.form-login-container {
    height: 100vh;
    color: #fff;
}

.form-login {
    max-width: 390px;
    padding: 15px;
    margin: auto;
}
  
.form-login > .form-floating > .form-label {
    color: #000;
}
  